import * as TS from "../../data/types";
import { Icon, Icons } from "../controls";

type StatusIconProps = {
  status: TS.BookingRequestStatus
}
export default function StatusIcon({status} : StatusIconProps) {
  // pre-render
  let icon = Icons.HourglassHalf;
  let tooltip = "offen";
  switch(status) {
    case "accepted":
      icon = Icons.ThumbsUp;
      tooltip = "akzeptiert";
      break;
    case "rejected":
      icon = Icons.ThumbsDown;
      tooltip = "abgelehnt";
      break;
    default:
      icon = Icons.Question;
      tooltip = "offen";
      break;
  }
  // render
  return (
    <Icon icon={icon} className={`icon ${status}`} tooltip={tooltip}  />
  )
}
import moment from "moment";
import { useEffect, useState, useCallback } from "react";

// types
import * as TS from "../../data/types";

// components
import { Redirect } from "react-router";
import { Alert, Button, DashboardTile, Dropdown, DropdownItem, Icon, Icons, LabeledText, Loading, StatusIcon } from "../controls";
import BookingRequest from "./BookingRequest.Edit";
import Page from "../layout/Page";

// styling
import CSS from "./BookingRequests.module.scss";
import { PageBreadcrumb, PageContent, PageHeading } from "../layout";
import { BookingRequestPeriod, BookingRequestStatus } from "../../data/types";

// the component
type BookingRequestsProps = {
  display: "page"|"dashboard"
}
export default function BookingRequests({display} : BookingRequestsProps) {
  // state
  const [mode, setMode] = useState<"loading"|"ready"|"error">("loading");
  const [redirectTo, setRedirectTo] = useState<null|string>(null);
  const [filterByStatus, setFilterByStatus] = useState<TS.BookingRequestStatus>("open");
  const [filterByPeriod, setFilterByPeriod] = useState<TS.BookingRequestPeriod>("upcoming");
  
  const [currentBookingRequest, setCurrentBookingRequest] = useState<TS.BookingRequest|null>(null);
  const [bookingRequests, setBookingRequests] = useState<TS.BookingRequest[]>([]);

  // loads data
  const load = useCallback(async (status: TS.BookingRequestStatus) => {
    setMode("loading");
    const result = await TS.BookingRequest.load(status);
    if(result.status === "OK") {
      setBookingRequests(result.bookingRequests);
      setMode("ready");
    }
    else if(result.status === "UNAUTHORIZED") {
      setRedirectTo("/login");
    }
    else {
      setMode("error");
    }
  }, [])

  // mount / filter changes
  useEffect(() => {
    load(filterByStatus);
  }, [filterByStatus, load]);

  // close dialog
  const closeDialog = (reload:boolean) => {
    setCurrentBookingRequest(null);
    if(reload) {
      load(filterByStatus);
    }
  }

  // render redirect?
  if(redirectTo) {
    return <Redirect to={redirectTo} push={true} />
  }

  // prerender
  let content = null;
  if(mode === "error") {
    content = <Alert title="Ladefehler" intent="error">
      Die Buchungsanfragen konnten nicht geladen werden
    </Alert>
  }
  else { 
    if(display === "page") {
      // pre-render booking requests
      const items = bookingRequests
        .filter(br => filterByPeriod === "past" ? !br.isUpcoming : br.isUpcoming)
        .map(br => <Item key={br.id} bookingRequest={br} onSelect={(br) => setCurrentBookingRequest(br)} disabled={mode === "loading"} />);   
        
      // pre-render content
      content = (
        <div className={CSS.container}>
          <div className={CSS.filter}>
            <Dropdown label="Status" onChange={v => setFilterByStatus(v as BookingRequestStatus)} value={filterByStatus}>
              <DropdownItem label="offen" value="open" />
              <DropdownItem label="akzeptiert" value="accepted" />
              <DropdownItem label="abgelehnt" value="rejected" />
            </Dropdown>
            <Dropdown label="Periode" onChange={v => setFilterByPeriod(v as BookingRequestPeriod)} value={filterByPeriod}>
              <DropdownItem label="bevorstehende" value={"upcoming"} />
              <DropdownItem label="vergangene" value={"past"} />
            </Dropdown>
          </div>
          {mode === "loading" ? <Loading /> : null}
          <div className={CSS.items}>
            {items.length > 0 ? items : <NoItemsFound disabled={mode === "loading"} />}
          </div>
        </div>
      )
    }
    else {
      const upcoming = bookingRequests.filter(br => br.isUpcoming);
      content = (
        <div className={CSS.container_dashboard}>
          <span>{upcoming.length}</span> offene Buchungsanfrage{upcoming.length > 1 ? "n" : ""}
        </div>
      );
    }
    
  }

  // render
  if(display === "page") {
    return <>
      <Page>
        <PageBreadcrumb title="Buchungsanfragen" icon={Icons.CalendarCheck} />
        <PageHeading title="Buchungsanfragen" icon={Icons.CalendarCheck} />
        <PageContent>
          {content}
        </PageContent>
      </Page>
      {currentBookingRequest ? <BookingRequest bookingRequest={currentBookingRequest} onClose={closeDialog} /> : null}
    </>;
  }
  else {
    return <DashboardTile title="Offene Buchungsanfragen" icon={Icons.CalendarCheck} onClick={() => setRedirectTo("/bookingrequests")}>
      {content}
    </DashboardTile>
  }
}

type ItemProps = {
  bookingRequest: TS.BookingRequest,
  onSelect: (bookingRequest:TS.BookingRequest) => void,
  disabled?: boolean,
}
function Item({bookingRequest, onSelect, disabled} : ItemProps) {
  const [showDetails, setShowDeatils] = useState<boolean>(false);
  disabled = disabled ? true : false;
  
  const services:any[] = [
    // adventure short id
    <LabeledText label="Referenz-Id" key="refid">
      {bookingRequest.adventureIdShort}
    </LabeledText>,
    // add provider name, i.e. the location
    <LabeledText label="Standort" key="providerName">
      {bookingRequest.providerName}
    </LabeledText>,
    // date and time
    <LabeledText label="Datum & Zeit" key="date_and_time">
      {moment(bookingRequest.activityDate).format("DD.MM.YYYY - HH:mm")}
    </LabeledText>
  ];
 

  // other services
  if(showDetails) {
    // other services
    bookingRequest.services
      .filter(s => {
        return s.key !== "Datum" && s.key !== "Zeit"
      })
      .forEach((s, index) => {
        let label = s.key;
        let value = s.value;
        services.push (
          <LabeledText key={index} label={label}>{value}</LabeledText>
        )
      });

    // add collective invoice value (if applicable)
    if(bookingRequest.collectiveInvoice) {
      services.push(
        <LabeledText label="Betrag auf Monatsabrechnung">
          {bookingRequest.collectiveInvoice.amount.toFixed(2)}
        </LabeledText>
      )
    }
    // add operator
    services.push(
      <LabeledText label="Ihr Kontakt bei Appentura" key="operator" className={CSS.operator}>
        <a href={`mailto:${bookingRequest.operator.email}`}><Icon icon={Icons.Envelope} /> {bookingRequest.operator.name}</a>
      </LabeledText>
    );
    // add comment
    if((bookingRequest?.comment ?? "").trim().length > 0) {
      services.push(
        <LabeledText label="Zusatzinformationen">
          {bookingRequest.comment.trim().split("\n").map((s:string, index:number) => <div key={index}>{s}</div>)}
        </LabeledText>
      )
    }
  }
  
  // render
  return (
    <div className={CSS.item}>
      <div className={`${CSS.status} ${CSS[bookingRequest.response.status]}`}>
        <StatusIcon status={bookingRequest.response.status} />
      </div>
      <div className={CSS.services_and_action}>
        <div className={CSS.services}>
          {services}
        </div>
        <div className={CSS.action}> 
          <Button onClick={() => setShowDeatils(!showDetails)} intent="cancel">
            <Icon icon={showDetails ? Icons.Minus : Icons.Plus} />
          </Button>
          {
            bookingRequest.isEditable ? 
              <Button disabled={disabled} onClick={() => onSelect(bookingRequest)}>bearbeiten <Icon icon={Icons.AngleRight} /></Button> : 
              null
          }
        </div>
      </div>
    </div>
  );
}

type NoItemsFoundProps = {
  disabled?: boolean;
}
function NoItemsFound({disabled} : NoItemsFoundProps) {
  return <div className={`${CSS.no_items_found} ${disabled ? CSS.disabled : ""}`}>keine Einträge gefunden</div>;
}



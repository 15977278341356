import { callApi } from "../api";

export interface PartnerLocation {
  id: string,
  name: string,
}
export interface PartnerCollectiveInvoice {
  isActive: boolean;
  email: string;
  currency: "CHF"|"EUR";
}
// TODO rename to Partner
export interface PartnerInfo {
  email: string,
  locations: PartnerLocation[],
  collectiveInvoice: PartnerCollectiveInvoice,
}


export class PartnerHelper {
  static async sendLoginLink(email:string) : Promise<{status:"OK"|"UNKNOWN_USER"|"ERROR", error:string|null}> {
    // call api
    const result = await callApi("sendLoginLink", {email});
    // error?
    let error = null;
    if(result.status === "ERROR") {
      error = result.error;
    }
    // done
    return {status: result.status, error}
  }

  static async getInfo() : Promise<{status:"OK"|"UNAUTHORIZED"|"ERROR", partnerInfo:PartnerInfo|null, error:string|null}> {
    // call api - Note: user is identified via the x-access-token in the header sent by the api
    const result = await callApi("getInfo", {});

    // status
    const status = result.status;
    
    // partner info
    let partnerInfo = null;
    if(result.status === "OK") {
      const locations:PartnerLocation[] = result.info.locations.map((location:any) => {
        return location as PartnerLocation;
      });
      partnerInfo = {
        email: result.info.email,
        collectiveInvoice: result.info.collectiveInvoice,
        locations
      }
    }

    // error?
    let error = null;
    if(result.status === "ERROR") {
      error = result.error;
    }

    // done
    return {status, partnerInfo, error}
  }
}

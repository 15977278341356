import CSS from "./Button.module.scss";
import Icon from "./Icon";
import Icons from "./Icons";

type ButtonProps = {
  onClick: (data?:any) => void,
  children: React.ReactNode|React.ReactNode[],
  disabled?: boolean,
  intent?: "ok"|"cancel",
  size?: "small"|"regular"|"large",
  busy?: boolean,
  data?: any
}
export default function Button({onClick, children, data, disabled, busy, intent, size} : ButtonProps) {
  // default props
  intent = intent || "ok";
  size = size || "regular";

  // user clicks
  const _onClick = () => {
    if(!disabled && !busy) {
      onClick(data);
    }
  }

  // busy?
  let busyDiv = null;
  if(busy) {
    busyDiv = <div className={CSS.spin_container}><Icon icon={Icons.CircleNotch} className={CSS.spin} /></div>
  }
  // render
  const cssIntent = CSS[`intent-${disabled ? "disabled" : intent}`];
  const cssSize = CSS[`size-${size}`];
  const cssBusy = busy ? CSS.busy : "";
  return (
    <div className={`${CSS.container} ${cssIntent} ${cssSize} ${cssBusy}`} onClick={_onClick}>
      <div className={CSS.inner}>
        <div className={CSS.content}>{children}</div>
        {busyDiv}
      </div>
    </div>
  )
}
import api from './api'

// the environment
const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
// days before adventure a booking request can still be edited
const bookingRequestThreshold = 2;


const config = {
  environment,
  api:api[environment],
  bookingRequestThreshold
};

export default config
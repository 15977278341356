import React from "react";
import moment from "moment";
import { useState } from "react";
import { BookingRequest } from "../../data/types/BookingRequest";
import { Button, StatusIcon, LabeledText, Modal, TextArea } from "../controls";

import CSS from "./BookingRequest.Edit.module.scss";

type BookingRequestViewProps = {
  bookingRequest: BookingRequest,
  onClose: (reload:boolean) => void
}
export default function BookingRequestView({bookingRequest, onClose} : BookingRequestViewProps) {
  // state
  const [status, setStatus] = useState<"accepted"|"rejected"|null>(null);
  const [comment, setComment] = useState<string>("");

  // wants to save
  const onConfirm = async() => {
    if(status) {
      const accepted = status === "accepted";
      // TODO probably should do const result = ... and then check the result
      await BookingRequest.setResponse(bookingRequest.id, accepted, comment, "");
      onClose(true);
    }
    onClose(false);
  }

  // pre-render services
  const services:Array<React.ReactNode> = [
    // adventure short id
    <LabeledText label="Referenz-Id" key="refid">
      {bookingRequest.adventureIdShort}
    </LabeledText>,
    // add provider name, i.e. the location
    <LabeledText label="Standort" key="providerName">
      {bookingRequest.providerName}
    </LabeledText>,
    // date and time
    <LabeledText label="Datum & Zeit" key="date_and_time">
      {moment(bookingRequest.activityDate).format("DD.MM.YYYY - HH:mm")}
    </LabeledText>,
  ];

  (bookingRequest.services || [])
    .filter(s => {
      return s.key !== "Datum" && s.key !== "Zeit"
    })
    .forEach((service, index) => {
      services.push(
        <LabeledText key={index} label={service.key}>{service.value}</LabeledText>
      );
    });

  // add collective invoice value (if applicable)
  if(bookingRequest.collectiveInvoice) {
    services.push(
      <LabeledText label="Betrag auf Monatsabrechnung">
        {bookingRequest.collectiveInvoice.amount.toFixed(2)}
      </LabeledText>
    )
  }

  // add comment
  if((bookingRequest?.comment ?? "").trim().length > 0) {
    services.push(
      <LabeledText label="Zusatzinformationen">
        {bookingRequest.comment.trim().split("\n").map((s:string, index:number) => <div key={index}>{s}</div>)}
      </LabeledText>
    )
  }

  // render
  return <Modal title="Buchungsanfrage bearbeiten" onClose={() => onClose(false)}>
    <div className={CSS.container}>
      {services}
      <div className="comment">
        <TextArea label="Kommentar" value={comment} onChange={s => setComment(s)} />
      </div>
      <div className={CSS.stati}>
        <div onClick={() => setStatus("rejected")} className={`${CSS.status} ${CSS.rejected} ${status === "rejected" ? CSS.active : ""}`}>
          <div className={CSS.status_inner}>
            <StatusIcon status="rejected" />
          </div>
        </div>
        <div onClick={() => setStatus("accepted")} className={`${CSS.status} ${CSS.accepted} ${status === "accepted" ? CSS.active : ""}`}>
          <div className={CSS.status_inner}>
          <StatusIcon status="accepted" />
          </div>
        </div>
      </div>

      <div className={CSS.actions}>
        <Button onClick={() => onClose(false)} intent="cancel">abbrechen</Button>
        <Button onClick={() => onConfirm()} intent="ok" disabled={status === null}>speichern</Button>
      </div>
    </div>
  </Modal>
  
}

import CSS from "./LabeledText.module.scss";

type LabeledTextProps = {
  label: string,
  children: React.ReactNode|React.ReactNode[],
  className?: string
}
export default function LabeledText({label, children, className} : LabeledTextProps) {
  return <div className={CSS.container}>
    <div className={CSS.label}>{label}</div>
    <div className={`${CSS.text} ${className || ""}`}>{children}</div>
  </div>
}

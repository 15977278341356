import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { AppProvider } from "./components/contexts/AppContext";
import Login from "./components/pages/Login";
import LoginLink from "./components/pages/LoginLink";
import Dashboard from "./components/pages/Dashboard";

import BookingRequests from "./components/views/BookingRequests";
import Profile from "./components/views/Profile";
import Contact from "./components/views/Contact";
import CollectiveInvoices from "./components/views/CollectiveInvoices";
import CollectiveInvoice from "./components/views/CollectiveInvoice";

import "./App.scss";

export default function App() {
  return (
    <Router>
      <div id="app">
        <AppProvider>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/loginlink/:token">
              <LoginLink />
            </Route>

            <Route path="/bookingrequests">
              <BookingRequests display="page" />
            </Route>
            <Route path="/contact">
              <Contact display="page" />
            </Route>
            <Route path="/company">
              <Profile display="page" />
            </Route>
            <Route path="/collectiveinvoices/:id">
              <CollectiveInvoice />
            </Route>
            <Route path="/collectiveinvoices">
              <CollectiveInvoices display="page" />
            </Route>
            

            <Route path="/">
              <Dashboard />
            </Route>
          </Switch>
        </AppProvider>
      </div>
    </Router>
  );
}
import { useState } from "react";
import {validate as validateEmail} from "email-validator";
import { PartnerHelper } from "../../data/types/Partner";
// components
import { Button, ContactItem, Icons } from "../controls";
// styling & images
import Images from "../../images";
import CSS from "./Login.module.scss";

export default function Login() {
  // state
  const [email, setEmail] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState(false);
  const [busy, setBusy] = useState(false);
  
  // on change email
  const onChangeEmail = (e:any) => {
    const email = e.target.value.trim().toLowerCase();
    const emailValid = validateEmail(email);
    setEmail(e.target.value);
    setEmailValid(emailValid);
  }

  // on submit
  const onClickSubmit = async() => {
    setBusy(true);
    setError(false);
    setSuccess(false);
    const result = await PartnerHelper.sendLoginLink(email);
    if(result.status === "OK") {
      setSuccess(true);
    }
    else {
      setError(true);
    }
    setBusy(false);
  }

  // render
  let divError = null;
  if(error) {
    divError = <div className={CSS.error}>
      <p>
        Leider war der Versuch dich anzumelden nicht erfolgreich. Überprüfe bitte die E-Mail Adresse oder nimm mit uns Kontakt auf.
      </p>
      
      <ContactItem text="hello@appentura.ch" href="mailto:hello@appentura.ch" icon={Icons.Envelope} />
    </div>
  }
  let divSuccess = null;
  if(success) {
    divSuccess = <div className={CSS.success}>
      <p>
        Du solltest in den nächsten Minuten ein E-Mail von uns erhalten.
      </p>
      <p>
        Folge den Anweisungen in diesem E-Mail, um dich in deinen Partner-Account einzuloggen.
      </p>
    </div>
  }
  let divInput = null;
  let divAction = null;
  if(!success) {
    divInput = <div className={CSS.input}>
      <input type="email" placeholder="" value={email} onChange={onChangeEmail} />
      <div className={CSS.email_invalid}>{emailValid ? "" : "Bitte eine gültige E-Mail Adresse eingeben"}</div>
    </div>
    divAction = <div className={CSS.action}>
      <Button disabled={!emailValid} busy={busy} onClick={onClickSubmit} size="large">Login</Button>
    </div>
  }
  return (
    <div className={CSS.container}>
      <div className={CSS.inner}>
        <div className={CSS.logo}>
          <img alt="appentura" src={Images.logo.white}></img>
          <div className={CSS.heading}>Appentura Partner Portal</div>
        </div>
        {divInput}
        {divError}
        {divSuccess}
        {divAction}
        
      </div>
    </div>
  )
}
import { useState } from "react";
import CSS from "./Dropdown.module.scss";

type DropdownProps = {
  label?:string,
  children: React.ReactNode|React.ReactNode[],
  value: string,
  onChange: (value:string) => void
}
export default function Dropdown({label, children, value, onChange} : DropdownProps) {
  const [selectedValue, setSelectedValue] = useState(value);

  const _onChange = (e:any) => {
    const v = e.target.value;
    setSelectedValue(v);
    onChange(v);
  }


  // render
  return (
    <div className={CSS.container}>
      <div className={CSS.inner}>
        <label>
          {label ? <div className={CSS.label}>{label}</div> : null }
          <select onChange={_onChange} value={selectedValue}>
            {children}
          </select>
        </label>
      </div>
    </div>
  )
}

<label></label>
import { useEffect } from "react";
import Icon from "./Icon";
import Icons from "./Icons";
import CSS from "./Modal.module.scss";

type ModalProps = {
  title: string,
  children: React.ReactNode|React.ReactNode[],
  onClose: () => void
}
export default function Modal({title, children, onClose} : ModalProps) {
  // mount / unmount
  useEffect(() => {
    // prevent background scrolling
    document.body.style.overflow = "hidden";
    // unmount
    return () => {
      // reactivate background scrolling
      document.body.style.overflow = "";
    }
  }, []);

  // user wants to close
  const _onClose = () => {
    // reactivate background scrolling
    document.body.style.overflow = "";
    onClose();
  }
  // render
  return (
    <div className={CSS.container}>
      <div className={CSS.inner}>
        <div className={CSS.heading}>
          <div className={CSS.heading_title}>{title}</div>
          <div className={CSS.heading_close} onClick={_onClose}>
            <Icon icon={Icons.Cancel} />
          </div>
        </div>
        <div className={CSS.content}>
          {children}
        </div>
      </div>
    </div>
  );
}
import { Icon } from "../controls";

import CSS from "./ContactItem.module.scss";

type ContactItemProps = {
  text: string,
  href?: string,
  icon: string[]
}
export default function ContactItem({text, icon, href} : ContactItemProps) {
  return (
    <div className={CSS.container}>
      <div className={CSS.icon}>
        <Icon icon={icon} />
      </div>
      <div className={CSS.text}>
        {href ? <a target="_blank" rel="noreferrer" href={href}>{text}</a> : text}
      </div>
    </div>
  )
}
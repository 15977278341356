import { callApi } from "../api";

export interface CollectiveInvoiceItem {
  _id: string;
  activity_date: Date;
  bookingRequest_date: Date;
  adventure_id: string;
  amount: number;
  text: string;
}

export interface CollectiveInvoicePeriod {
  month: number; // 0-11
  year: number;
}

export class CollectiveInvoice {
  id: string = "";
  dateSent: Date|null = new Date();
  datePaid: Date|null = null;
  period: CollectiveInvoicePeriod = { month:0, year:0 };
  items: CollectiveInvoiceItem[] = [];
  currency: "CHF"|"EUR" = "CHF";
  total: number = 0;

  get referenceId():string {
    return this.id.substring(this.id.length - 4, this.id.length);
  }

  static fromDb(obj:any): CollectiveInvoice {
    const ci = new CollectiveInvoice();
    ci.id = obj._id;
    ci.dateSent = obj.dateSent ? new Date(obj.dateSent) : null;
    ci.datePaid = obj.datePaid ? new Date(obj.datePaid) : null;
    ci.period = obj.period;
    ci.currency = obj.currency || "CHF";
    ci.items = (obj.items || []).map((item:any) => {
      return {
        _id: item._id,
        activity_date: item.activity_date ? new Date(item.activity_date) : null,
        bookingRequest_date: item.bookingRequest_date ? new Date(item.bookingRequest_date) : null,
        adventure_id: item.adventure_id,
        amount: item.amount,
        text: item.text
      };
    });
    ci.total = obj.items.reduce((prev:number, curr:CollectiveInvoiceItem) => prev + curr.amount, 0);

    // done
    return ci;
  }

  static async load() : Promise<{status:"OK"|"UNAUTHORIZED"|"ERROR", error:Error|null, collectiveInvoices:CollectiveInvoice[]}> {
    // call api - Note: user is identified via the x-access-token in the header sent by the api
    const result = await callApi("getCollectiveInvoices", {});

    // error?
    let error = null;
    if(result.status === "ERROR") {
      error = result.error;
      console.error(error);
    }

    // collective invoices
    const unsortedCollectiveInvoices: Array<CollectiveInvoice> = [];
    if(result.status === "OK") {
      result.collectiveInvoices.forEach((ci:any) => {
        unsortedCollectiveInvoices.push(CollectiveInvoice.fromDb(ci));
      });
    }

    // sort them
    const sortedCollectiveInvoices = unsortedCollectiveInvoices.sort((a,b) => {
      return a.period.year + a.period.month > b.period.year + b.period.month ? 1 : -1;
    })

    // done
    return {
      status: result.status,
      error,
      collectiveInvoices: sortedCollectiveInvoices
    };


  }

  static printPeriod(period:CollectiveInvoicePeriod):string {
    return `${period.month + 1}/${period.year}`;
  }

}


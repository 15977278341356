import { Icon } from "../controls";
import PageBlock from "./PageBlock";
import CSS from "./PageHeading.module.scss";

type PageHeadingProps = {
  title:string,
  icon:any
}
export default function PageHeading({title, icon} : PageHeadingProps) {
  return (
    <PageBlock>
      <div className={CSS.container}>
        <div className={CSS.icon}><Icon icon={icon} /></div>
        <div className={CSS.title}>{title}</div>
      </div>
    </PageBlock>
  )
}
import { useEffect, useState, useContext } from "react";
// types
import * as TS from "../../data/types";
// components and contexts
import { AppContext } from "../contexts/AppContext";
import { Redirect } from "react-router";
import { Alert, DashboardTile, Icons, LabeledText, Loading } from "../controls";
import { Page, PageBreadcrumb, PageContent, PageHeading} from "../layout";
// styling
import CSS from "./Profile.module.scss";

// the component
type ProfileProps = {
  display:"page"|"dashboard",
}
export default function Profile({display} : ProfileProps) {
  // context
  const App = useContext(AppContext);

  // state// state
  const [isLoading, setIsLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState<null|string>(null);
  const [error, setError] = useState<string|undefined>();

  // mount
  useEffect(() => {
    const load = async() => {
      const result = await TS.PartnerHelper.getInfo();
      if(result.status === "OK" && result.partnerInfo) {
        if(!App.partnerInfo) {
          App.setPartnerInfo(result.partnerInfo);
        }
      }
      else if(result.status === "UNAUTHORIZED") {
        setRedirectTo("/login");
      }
      else { 
        setError(result.error || "Unknown error"); 
      }
      setIsLoading(false);
    }
    load();
  }, [App]);

  
  // render - not logged in
  if(redirectTo) {
    return <Redirect to={redirectTo} push={true} />
  }

  // pre-render
  let content = null;
  if(isLoading) {
    // pre-render - still loading
    content = <Loading />
  }
  else if(error) {
    // pre-render - error
    content = <Alert intent="error" title="Fehler beim Laden">
      {error ? error : "Informationen konnten nicht geladen werden"}
    </Alert>
  }
  else {
    if(display === "page") {
      const locations = App.partnerInfo?.locations.map((info, index) => {
        return (
          <div key={index} className={CSS.location}>
            <div className={CSS.location_inner}>
              <LabeledText label="Standort">{info.name}</LabeledText>
              <LabeledText label="id">{info.id}</LabeledText>
            </div>
          </div>
        );
      })
      content = (
        <div>
          <LabeledText label="E-Mail Adresse für Buchungsanfragen">{App.partnerInfo?.email}</LabeledText>
          <br />
          <div>
            {locations}
          </div>    
        </div>
      );
    }
    else {
      content = (
        <div>
          <LabeledText label="E-Mail Adresse für Buchungsanfragen">{App.partnerInfo?.email}</LabeledText>
          <LabeledText label="Standorte">{App.partnerInfo?.locations.map(loc => loc.name).join(" | ")}</LabeledText>
        </div>
      );
    }  
  }


  // render
  if(display === "page") {
    return <Page>
      <PageBreadcrumb title="Ihr Profil" icon={Icons.Building} />
      <PageHeading title="Ihr Profil" icon={Icons.Building} />
      <PageContent>
        {content}
        <br />
        <Alert title="Falsche Angaben?" className={CSS.hint}>
          Sind die Angaben nicht korrekt? Bitte melde dich doch bitte bei <a href="mailto:hello@appentura.ch">hello@appentura.ch</a> :)
        </Alert>
      </PageContent>
    </Page>
  }
  else {
    return <DashboardTile title="Ihr Profil" icon={Icons.Building} onClick={() => setRedirectTo("/company")}>
      {content}
    </DashboardTile>
  }
  
}
import CSS from "./Alert.module.scss";

type AlertProps = {
  title: string,
  children: React.ReactNode|React.ReactNode[],
  intent?: "error"|"info",
  className?: string
}
export default function Alert({title, children, intent, className} :  AlertProps) {
  // default props
  intent = intent || "info";

  // render
  const intentCss = CSS[`intent-${intent}`];
  return <div className={`${CSS.container} ${intentCss}`}>
    <div className={CSS.title}>
      {title}
    </div>
    <div className={`${CSS.message} ${className || ""}`}>
      {children}
    </div>
  </div>
}
import { useState, useContext, useEffect } from "react";

import { Link, Redirect } from "react-router-dom";
import { Icon, Icons } from "../controls";
import { AppContext } from "../contexts/AppContext";
import Images from "../../images";

import CSS from "./Page.module.scss";

type PageProps = {
  children: React.ReactNode|React.ReactNode[]
}
export default function Page({children} : PageProps) {
  // context
  const App = useContext(AppContext);

  // render
  return <>
    <div className={CSS.page}>
      <Header />
      {children}  
      <Footer />
    </div>
    {App.menuVisible ? <Menu /> : null}
  </>

}

function Header() {
  // context
  const App = useContext(AppContext);
  // pre-render
  let titleLarge = "Appentura Partner Portal";
  let titleSmall = "";
  if(App.partnerInfo) {
    titleSmall = App.partnerInfo.locations.map(location => location.name).join(" | ");
  }
  // render
  return <>
    <div className={CSS.page_header}>
      <div className={CSS.page_header_inner}>
        <div className={CSS.menu_toggle} onClick={() => App.setMenuVisible(true)}>
          <Icon icon={Icons.Bars} />
        </div>
        <div className={CSS.page_header_title}>
          <Link to="/">
            <div className={CSS.large}>{titleLarge}</div>
            <div className={CSS.small}>{titleSmall}</div>
          </Link>
        </div>
      </div>
    </div>
  </>
}

function Footer() {  
  // render
  return (
    <div className={CSS.page_footer}>
      <div className={CSS.page_footer_inner}>
        <div className={CSS.page_footer_content}>
          <div className={CSS.logo}>
            <img alt="appentura" src={Images.logo.white}></img>
          </div>
          <div className={CSS.address}>
            Appentura AG, Bernapark 28, 3066 Stettlen / Bern
          </div>
          <div className={CSS.web}>
            <a href="https://www.appentura.ch" target="_blank" rel="noreferrer">appentura.ch</a>
          </div>
          <div className={CSS.phone}>
            <a href="tel:+41 31 511 21 32">+41 31 511 21 32</a>
          </div>
          <div className={CSS.email}>
            <a href="mailto:hello@appentura.ch">hello@appentura.ch</a>
          </div>
        </div>
      </div>
    </div>
  )
}


function Menu() {
  // context
  const App = useContext(AppContext);

  // status
  const [showCollectiveInvoice, setShowCollectiveInvoice] = useState<boolean>(false);

  // App context changes
  useEffect(() => {
    const showCollectiveInvoice = App.partnerInfo !== null && App.partnerInfo.collectiveInvoice.isActive;
    setShowCollectiveInvoice(showCollectiveInvoice);
  }, [App.partnerInfo]);

  // render
  return (
    <div className={CSS.menu}>
      <div className={CSS.menu_inner}>
        <div className={CSS.menu_inner_content}>
          <div className={CSS.close} onClick={() => App.setMenuVisible(false)}><Icon icon={Icons.Cancel} /></div>
          <MenuItem title="Übersicht" icon={Icons.Home} to="/" />
          <MenuItem title="Buchungsanfragen" icon={Icons.CalendarCheck} to="/bookingrequests" />
          <MenuItem title="Ihre Profil" icon={Icons.Building} to="/company" />
          <MenuItem title="Kontakt" icon={Icons.Headset} to="/contact" />
          {showCollectiveInvoice ? <MenuItem title="Monatsabrechnungen" icon={Icons.FileInvoice} to="/collectiveinvoices" /> : null}
          
        </div>
        
      </div>
    </div>
  )
}

type MenuItemProps = {
  title: string,
  icon: any,
  to: string
}
function MenuItem(props: MenuItemProps) {
  // context
  const App = useContext(AppContext);

  // state
  const [redirect, setRedirect] = useState<boolean>(false);

  
  // redirect?
  if(redirect) {
    App.setMenuVisible(false);
    return <Redirect to={props.to} push={true} />
  }

  return (
    <div className={CSS.menu_item} onClick={() => setRedirect(true)}>
      <div className={CSS.icon}>
        <Icon icon={props.icon} />
      </div>
      <div className={CSS.title}>
        {props.title}  
      </div>
    </div>
  )
}
// fas: solid: https://fontawesome.com/icons?d=gallery&q=I&m=free
// far: regular (outline): https://fontawesome.com/icons?d=gallery&q=I&s=regular&m=free

import { library } from '@fortawesome/fontawesome-svg-core'
// solid
import {
  faAngleRight,
  faAngleLeft,
  faBars,
  faCheck,
  faCircleNotch,
  faExclamationCircle,
  faFileInvoice,
  faGlobe,
  faGripHorizontal,
  faGripVertical,
  faHeadset,
  faHome,
  faHourglassHalf,
  faMapMarkerAlt,
  faMinus,
  faPhone,
  faPlus,
  faQuestion,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
// regular
import {
  faBuilding,
  faCalendar,
  faCalendarCheck,
  faEnvelope,
  faLightbulb,
  faMinusSquare,
  faPlusSquare,
  faThumbsDown,
  faThumbsUp,
  faTrashAlt, 
} from "@fortawesome/free-regular-svg-icons";

// library
library.add(
  faAngleLeft,
  faAngleRight,
  faBars,
  faBuilding,
  faCalendar,
  faCalendarCheck,
  faCheck,
  faCircleNotch,
  faExclamationCircle,
  faEnvelope,
  faFileInvoice,
  faGlobe,
  faGripHorizontal,
  faGripVertical,
  faHeadset,
  faHome,
  faHourglassHalf,
  faLightbulb,
  faMapMarkerAlt,
  faMinus,
  faMinusSquare,
  faPhone,
  faPlus,
  faPlusSquare,
  faQuestion,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTrashAlt
);

// Icons
const Icons = {
  AngleLeft: ['fas', 'angle-left'],
  AngleRight: ['fas', 'angle-right'],
  Bars: ['fas', 'bars'],
  Building: ['far', 'building'],
  Calendar: ['far', 'calendar'],
  CalendarCheck: ['far', 'calendar-check'],
  Cancel: ['fas', 'times'],
  CircleNotch: ['fas', 'circle-notch'],
  Confirm: ['fas', 'check'],
  Envelope: ['far', 'envelope'],
  ExclamationCircle: ['fas', 'exclamation-circle'],
  FileInvoice: ['fas', 'file-invoice'],
  Globe: ['fas', 'globe'],
  GripHorizontal: ['fas', 'grip-horizontal'],
  GripVertical: ['fas', 'grip-vertical'],
  Headset: ['fas', 'headset'],
  Home: ['fas', 'home'],
  HourglassHalf: ['fas', 'hourglass-half'],
  Question: ['fas', 'question'],
  Lightbulb: ['far', 'lightbulb'],
  MapMarker: ['fas', 'map-marker-alt'],
  Minus: ['fas', 'minus'],
  MinusSquare: ['far', 'minus-square'],
  Phone: ['fas', 'phone'],
  Plus: ['fas', 'plus'],
  PlusSquare: ['far', 'plus-square'],
  ThumbsDown: ['far', 'thumbs-down'],
  ThumbsUp: ['far', 'thumbs-up'],
  Trash: ['far', 'trash-alt']
};

export default Icons
import { useParams, Redirect } from "react-router-dom";
import * as LocalStorage from "../../data/localStorage";

export default function LoginLink() {
  // read params
  const params:any = useParams();
  const token:string|null = params.token;
  
  // render
  if(token) {
    LocalStorage.setAccessToken(token);
    return <Redirect to="/" />
  }
  else { 
    return <Redirect to="/login" />;
  }
}
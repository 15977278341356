import CSS from  "./Loading.module.scss";

type LoadingProps = {
  text?:string,
  kind?:"line"
}
export default function Loading({kind, text} : LoadingProps) {
  // default props
  kind = kind || "line";
  
  // pre-render
  const divText = (text || "").trim().length > 0 ? <div className={CSS.text}>{text}</div> : null;
  // render
  return (
    <div className={CSS.container}>
      {divText}
      <div className={CSS.line} />
    </div>
  )
}


import { useState } from "react";
// components
import { DashboardTile } from "../controls";
import { Icons, ContactItem } from "../controls";
import { Page, PageBreadcrumb, PageContent, PageHeading} from "../layout";
import { Redirect } from "react-router";

// the component
type ContactProps = {
  display: "dashboard"|"page"
}
export default function Contact({display} : ContactProps) {
  //state
  const [redirectTo, setRedirectTo] = useState<null|string>(null);

  // render redirect?
  if(redirectTo) {
    return <Redirect to={redirectTo} push={true} />
  }

  // render
  if(display === "page") {
    return (
      <Page>
        <PageBreadcrumb icon={Icons.Headset} title="Kontakt" />
        <PageHeading title="Kontakt" icon={Icons.Headset} />
        
        <PageContent>
          <ContactItem text="Appentura AG, Bernapark 28, 3066 Stettlen / Bern" href="https://goo.gl/maps/7JcjyxHSdFAMGtPg6" icon={Icons.MapMarker} />
          <ContactItem text="appentura.ch" href="https://www.appentura.ch" icon={Icons.Globe} />
          <ContactItem text="+41 31 511 21 32" href="tel:+41 31 511 21 32" icon={Icons.Phone} />
          <ContactItem text="hello@appentura.ch" href="mailto:hello@appentura.ch" icon={Icons.Envelope} />
        </PageContent>
      </Page>
    )
  }
  else { 
    return (
      <DashboardTile title="Kontakt" icon={Icons.Headset} onClick={() => setRedirectTo("/contact")}>
        <ContactItem text="+41 31 511 21 32" href="tel:+41 31 511 21 32" icon={Icons.Phone} />
      </DashboardTile>
    );
  }
  
}

import CSS from "./PageBlock.module.scss";

type PageBlockProps = {
  children: React.ReactNode|React.ReactNode[]
}
export default function PageBlock({children} : PageBlockProps) {
  return (
    <div className={CSS.container}>
      <div className={CSS.inner}>
        {children}
      </div>
    </div>
  )
}
import { Link } from "react-router-dom";
import { Icon, Icons } from "../controls";
import PageBlock from "./PageBlock";

import CSS from "./PageBreadcrumb.module.scss";

type PageBreadcrumbParent = {
  title: string,
  icon: any,
  to: string
}
type PageBreadcrumbProps = {
  parents?: PageBreadcrumbParent[],
  title:string,
  icon:any
}
export default function PageBreadcrumb({title, icon, parents=[]} : PageBreadcrumbProps) {
  // render
  return (
    <PageBlock>
      <div className={CSS.container}>
        <div className={CSS.inner}>
          <Parent parent={{title: "Home", icon:Icons.Home, to:"/"}} />
          {parents.map((p, index) => <Parent key={index} parent={p} />)}
          <div className={CSS.location}>
            <div className={CSS.icon}><Icon icon={icon} /></div>
            <div className={CSS.title}>{title}</div>
          </div>
        </div>
      </div>
    </PageBlock>
  )
}

type ParentProps = {
  parent: PageBreadcrumbParent
}
function Parent({parent}: ParentProps) {
  return (
    <div className={CSS.parent}>
      <Link to={parent.to}>
        <div className={CSS.link}>
          <div className={CSS.icon}><Icon icon={parent.icon} /></div>
          <div className={CSS.title}>{parent.title}</div>
        </div>
      </Link>
      <div className={CSS.divider}>/</div>
    </div>
  )
}
import React, {useEffect, useState} from "react";
import * as TS from "../../data/types";

// context
type AppContextType = {
  menuVisible: boolean,
  partnerInfo: TS.PartnerInfo|null,

  setMenuVisible: (visible:boolean) => void,
  setPartnerInfo: (info:TS.PartnerInfo) => void,
}
export const AppContext = React.createContext<AppContextType>({} as AppContextType);

// provider
type AppProviderProps = {
  children: React.ReactNode|React.ReactNode[]
}
export function AppProvider(props: AppProviderProps) {
  // state
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [partnerInfo, setPartnerInfo] = useState<TS.PartnerInfo|null>(null);
  
  // mount
  useEffect(() => {}, [])
  
  // provider value
  const value = {
    // values
    menuVisible,
    partnerInfo,

    // functions
    setMenuVisible: (visible:boolean) => {
      setMenuVisible(visible);
    },
    setPartnerInfo: (partnerInfo:TS.PartnerInfo) => {
      setPartnerInfo(partnerInfo);
    }
  }

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  )
}


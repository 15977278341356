import { useState } from "react";
import CSS from  "./TextArea.module.scss";

type TextAreaProps = {
  value: string;
  label: string;
  rows?: number;
  onChange: (text:string) => void;
}
export default function TextArea({label, rows, onChange, value} : TextAreaProps) {
  // default values
  rows = rows || 3;

  // state 
  const [text, setText] = useState(value);

  // value changed
  const _onChange = (e:any) => {
    onChange(e.target.value);
    setText(e.target.value);
  };
  
  // render
  return (
    <div className={CSS.container}>
      <div className={CSS.inner}>
        <label>
          <div className={CSS.label}>{label}</div>
          <textarea value={text} onChange={_onChange} rows={rows} />
        </label>
      </div>
    </div>
  )
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CSS from "./Icon.module.scss";

type IconProps = {
  className?: string,
  data?: any,
  onClick?: (data:any) => void,
  tooltip?: string,
  icon: any
}
export default function Icon({onClick, data, className, tooltip, icon} : IconProps) {
  const click =() => {
    if(onClick) {
      onClick(data);
    }
  }
  return <span className={`${CSS.container} ${className || ""}`} onClick={click} title={tooltip || ''}>
    <FontAwesomeIcon icon={icon} />
  </span>
}


import CSS from "./PageContent.module.scss";

type PageContentProps = {
  children: React.ReactNode|React.ReactNode[]
}
export default function PageContent({children} : PageContentProps) {
  return (
    <div className={CSS.container}>
      <div className={CSS.inner}>
        <div className={CSS.content}>{children}</div>
      </div>
    </div>
  )
}
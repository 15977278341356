import Config from "../config";
import * as LocalStorage from "./localStorage";

export async function callApi(endpoint:string, payload:any): Promise<any> {
  // fetch data from api
  const res = await fetch(`${Config.api.root}/${endpoint}`, {  
    method:"POST",
    headers: {
      "Content-Type": "application/json", 
      "x-access-token": LocalStorage.getAccessToken() || "",
    },
    body: JSON.stringify(payload)
  });
  const result = await res.json();
  return result;
}


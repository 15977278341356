import moment from 'moment';

export class Util {
  static printDate(date:Date|null|undefined):string {
    if(date) {
      return moment(date).format("DD.MM.YYYY");
    }
    else {
      return "-";
    }
  }

  static printDateTime(date:Date|null|undefined):string {
    if(date) {
      return moment(date).format("DD.MM.YYYY, HH:mm");
    }
    else {
      return "-";
    }
  }
}
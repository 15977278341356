const config = {
  development: {
    root:`http://${window.location.hostname}:8090/v1/partner`
  },
  staging: {
    root:"https://api-staging.appentura.io/v1/partner"
  },
  production: {
    root:"https://api.appentura.io/v1/partner"
  }
};

export default config
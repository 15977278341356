import { useEffect, useState, useContext } from "react";
// types
import * as TS from "../../data/types";
// components and contexts
import { AppContext } from "../contexts/AppContext";
import { Link, Redirect } from "react-router-dom";
import { Alert, DashboardTile, Icons, LabeledText, Loading } from "../controls";
import { Page, PageBreadcrumb, PageContent, PageHeading} from "../layout";
// styling
import CSS from "./CollectiveInvoices.module.scss";

// the component
type CollectiveInvoicesProps = {
  display:"page"|"dashboard",
}
export default function CollectiveInvoices({display} : CollectiveInvoicesProps) {
  // context
  const App = useContext(AppContext);

  // state// state
  const [isLoading, setIsLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState<null|string>(null);
  const [error, setError] = useState<string|undefined>();
  const [collectiveInvoices, setCollectiveInvoices] = useState<TS.CollectiveInvoice[]>([]);
  const [featuredAvailable, setFeatureAvailable] = useState<boolean>(false); // whether the partner has collective invoices activated

  // mount
  useEffect(() => {
    const load = async() => {
      const resultPartner = await TS.PartnerHelper.getInfo();
      const resultCollectiveInvoices = await TS.CollectiveInvoice.load();

      // not authorized? redirect to login
      if(resultPartner.status === "UNAUTHORIZED" || resultCollectiveInvoices.status === "UNAUTHORIZED") {
        setRedirectTo("/login");
        return;
      }

      // error?
      if(resultPartner.status === "ERROR") {
        setError(resultPartner.error || "Unknown error"); 
        setIsLoading(false);
        return;
      }
      if(resultCollectiveInvoices.status === "ERROR") {
        setError(resultCollectiveInvoices.error?.message || "Unknown error"); 
        setIsLoading(false);
        return;
      }

      // feed partner info to App context if it does not have it
      if(resultPartner.status === "OK" && resultPartner.partnerInfo) {
        if(!App.partnerInfo) {
          App.setPartnerInfo(resultPartner.partnerInfo);
        }
        setFeatureAvailable(resultPartner.partnerInfo.collectiveInvoice.isActive);
      }

      // put collective invoices into state
      setCollectiveInvoices(resultCollectiveInvoices.collectiveInvoices);

      // done
      setIsLoading(false);
    }
    load();
  }, [App]);

  
  // render - not logged in
  if(redirectTo) {
    return <Redirect to={redirectTo} push={true} />
  }

  // render - feature not active
  if(!featuredAvailable) {
    return null;
  }

  // pre-render
  let content = null;
  if(isLoading) {
    // pre-render - still loading
    content = <Loading />
  }
  else if(error) {
    // pre-render - error
    content = <Alert intent="error" title="Fehler beim Laden">
      {error ? error : "Informationen konnten nicht geladen werden"}
    </Alert>
  }
  else {
    if(display === "page") {
      const itemsOpen: TS.CollectiveInvoice[] = [];
      const itemsPaid: TS.CollectiveInvoice[] = [];
      collectiveInvoices.forEach(ci => {
        ci.datePaid ? itemsPaid.push(ci) : itemsOpen.push(ci);
      })
      content = (
        <div>
          <LabeledText label="E-Mail Adresse für Monatsabrechnung">{App.partnerInfo?.collectiveInvoice.email}</LabeledText>
          <br />
          <List heading="Offene Abrechnungen" collectiveInvoices={itemsOpen} showPaymentDate={false} />
          <List heading="Bezahlte Abrechnungen" collectiveInvoices={itemsPaid} showPaymentDate={true} />
        </div>
      );
    }
    else {
      const open = collectiveInvoices.filter(ci => ci.datePaid === null);
      content = (
        <div className={CSS.container_dashboard}>
          <LabeledText label="E-Mail Adresse für Monatsabrechnung">{App.partnerInfo?.collectiveInvoice.email}</LabeledText>
          <LabeledText label="Monatsabrechnungen">
            <span>{open.length}</span> offene Monatsabrechnungen
          </LabeledText>
        </div>
      );
    }  
  }


  // render
  if(display === "page") {
    return <Page>
      <PageBreadcrumb title="Monatsabrechnungen" icon={Icons.FileInvoice} />
      <PageHeading title="Monatsabrechnungen" icon={Icons.FileInvoice} />
      <PageContent>
        {content}
        <br />
        <Alert title="Falsche Angaben?" className={CSS.hint}>
          Sind die Angaben nicht korrekt? Bitte melde dich doch bitte bei <a href="mailto:hello@appentura.ch">hello@appentura.ch</a> :)
        </Alert>
      </PageContent>
    </Page>
  }
  else {
    return <DashboardTile title="Monatsabrechnungen" icon={Icons.FileInvoice} onClick={() => setRedirectTo("/collectiveinvoices")}>
      {content}
    </DashboardTile>
  }
  
}

type ListProps = {
  heading: string;
  collectiveInvoices: TS.CollectiveInvoice[],
  showPaymentDate: boolean,
}
function List({heading, collectiveInvoices, showPaymentDate}: ListProps) {
  const rows = collectiveInvoices.map(ci => {
    return <ItemRow collectiveInvoice={ci} showPaymentDate  />
  })

  let content = null;
  const thPaymentDate = showPaymentDate ? <th>Zahlungsdatum</th> : null;
  if(rows.length > 0) {
    content = (
      <table className={CSS.list}><tbody>
        <tr>
          <th>Periode</th>
          <th>Abrechnungsdatum</th>
          {thPaymentDate}
          <th className={CSS.right}>Buchungen</th>
          <th className={CSS.right}>Betrag</th>
          <th></th>
        </tr>
        {rows}
      </tbody></table>
    );
  }
  else {
    content = <div className={CSS.none}>keine Abrechnungen vorhanden</div>
  }
  return (
    <div className={CSS.list}>
      <div className={CSS.heading}>{heading}</div>
      {content}
    </div>
  )
}

type ItemRowProps = {
  collectiveInvoice: TS.CollectiveInvoice,
  showPaymentDate: boolean,
}
function ItemRow({collectiveInvoice, showPaymentDate}:ItemRowProps) {
  // render
  const tdPaymentDate = showPaymentDate && collectiveInvoice.datePaid ? <td>{TS.Util.printDate(collectiveInvoice.datePaid)}</td> : null;
  return <>
    <tr>
      <td>
        {TS.CollectiveInvoice.printPeriod(collectiveInvoice.period)}
      </td>
      <td>{TS.Util.printDate(collectiveInvoice.dateSent)}</td>
      {tdPaymentDate}
      <td className={CSS.right}>
        {collectiveInvoice.items.length}
      </td>
      <td className={CSS.right}>
        {collectiveInvoice.currency} {collectiveInvoice.total.toFixed(2)}
      </td>
      <td>
        <Link to={`/collectiveinvoices/${collectiveInvoice.id}`}>Details</Link>
      </td>
    </tr>
  </>
}

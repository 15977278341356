import logo_white from './logo_appentura_white.svg'
import logo_black from './logo_appentura_black.svg'
import icon_thumb_up from './icon_thumb_up.svg'

const images = {
  logo: {
    white:logo_white,
    black:logo_black,
  },
  icon: {
    thumb:icon_thumb_up
  }
}

export default images



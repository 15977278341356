import { Icon, Icons } from "../controls";
import CSS from "./DashboardTile.module.scss";

type TileProps = {
  title: string,
  children: React.ReactNode|React.ReactNode[],
  icon: any, 
  onClick: () => void
}
export default function Tile({title, children, icon, onClick} : TileProps) {
  

  // render
  return <div className={CSS.container}>
    <div className={CSS.container_inner}>
      <div className={CSS.top}>
        <div className={CSS.icon}>
          <Icon icon={icon} />
        </div>
        <div className={CSS.title}>{title}</div>
        <div className={CSS.content}>{children}</div>
      </div>

      <div className={CSS.bottom}>
        <div className={CSS.action} onClick={onClick}>
          <div className={CSS.action_inner}>
            <Icon icon={Icons.AngleRight} />
          </div>
        </div>
      </div>
    </div>
  </div>
}

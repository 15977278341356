import React, { useEffect, useState } from "react";
import * as TS from "../../data/types";
import { Redirect, useParams } from "react-router";
import { Alert, Icons, LabeledText, Loading } from "../controls";
import { Page, PageBreadcrumb, PageContent, PageHeading } from "../layout";

import CSS from "./CollectiveInvoice.module.scss";

export default function CollectiveInvoice() {
  // get requested id from router params
  const { id } = useParams<{id:string}>();
  // state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);
  const [needsLogin, setNeedsLogin] = useState<boolean>(false);
  const [collectiveInvoice, setCollectiveInvoice] = useState<TS.CollectiveInvoice|null>(null);

  // mount
  useEffect(() => {
    const load = async (id:string) => {
      // TODO reading all invoices ... could just read one, but in a hurry to get this done
      const resultCollectiveInvoices = await TS.CollectiveInvoice.load();

      // not authorized? redirect to login
      if(resultCollectiveInvoices.status === "UNAUTHORIZED") {
        setNeedsLogin(true);
        return;
      }

      // error?
      if(resultCollectiveInvoices.status === "ERROR") {
        setError(resultCollectiveInvoices.error?.message || "Unknown error"); 
        setIsLoading(false);
        return;
      }

      // get the invoice
      const collectiveInvoice = resultCollectiveInvoices.collectiveInvoices.find(ci => ci.id === id);
      if(collectiveInvoice) {
        setCollectiveInvoice(collectiveInvoice);
      }

      // done
      setIsLoading(false);
    }
    if(id) {
      load(id);
    }
  }, [id]);

  // redirect to login if necessary
  if(needsLogin) {
    return <Redirect to="/" />;
  }
  
  // pre-render content
  let content = null;
  let title = "";
  // still loading?
  if(isLoading) {
    content = <Loading />;
    title = "Lade Monatsabrechnung";
  }
  // error?
  else if(error) {
    content = <Alert intent="error" title="Fehler">{error}</Alert>
    title = "Fehler";
  }
  // not found
  else if(collectiveInvoice === null) {
    content = <Alert intent="error" title="Nicht gefunden">Monatsabrechnung mit der id {id} konnte nicht gefunden werden.</Alert>
    title = "Nicht gefunden";
  }
  else {
    title = `Monatsabrechnung ${collectiveInvoice.referenceId}`;
    let total = 0;
    const rows = collectiveInvoice.items.map(item => {
      total += item.amount;
      return (
        <tr key={item._id}>
          <td>{TS.Util.printDateTime(item.activity_date)}</td>
          <td>{item.text}</td>
          <td className={CSS.right}>{collectiveInvoice.currency} {item.amount.toFixed(2)}</td>
        </tr>
      )
    })
    content = (
      <div className={CSS.container}>
        <LabeledText label="Referenznummer">{collectiveInvoice.referenceId}</LabeledText>
        <LabeledText label="Periode">{TS.CollectiveInvoice.printPeriod(collectiveInvoice.period)}</LabeledText>
        <LabeledText label="Abrechnungsdatum">{TS.Util.printDate(collectiveInvoice.dateSent)}</LabeledText>
        {collectiveInvoice.datePaid ? <LabeledText label="Zahlungsdatum">{TS.Util.printDate(collectiveInvoice.datePaid)}</LabeledText> : null}
        <br />
        <table className={CSS.details}><tbody>
          <tr>
            <th>Datum</th>
            <th>Buchungstext</th>
            <th className={CSS.right}>Betrag</th>
          </tr>
          {rows}
          <tr>
            <td className={CSS.total}>TOTAL</td>
            <td className={`${CSS.total} ${CSS.right}`}>{collectiveInvoice.items.length} Buchung {collectiveInvoice.items.length > 1 ? "en" : ""}</td>
            <td className={`${CSS.total} ${CSS.right}`}>{collectiveInvoice.currency} {total.toFixed(2)}</td>
          </tr>
        </tbody></table>
        <br />
        <Alert title="Etwas nicht in Ordnung?">
          Sind die Angaben nicht korrekt? Bitte melde dich doch bitte bei <a href="mailto:hello@appentura.ch">hello@appentura.ch</a> :)
        </Alert>
      </div>  
    )
  }

  // render
  return (
    <Page>
      <PageBreadcrumb 
        title={id} icon={Icons.FileInvoice} 
        parents = {[
          { title: "Monatsabrechnungen", to: "/collectiveinvoices", icon: Icons.FileInvoice }
        ]}
      />
      <PageHeading title={title} icon={Icons.FileInvoice} />
      <PageContent>
        {content}
      </PageContent>
    </Page>
  )
}



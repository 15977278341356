import _ from "lodash";
import moment from "moment";
import { callApi } from "../api";

// Note: "responded" includes both "accepted" & "rejected"
export type BookingRequestStatus = "accepted"|"rejected"|"open"|"responded";
export type BookingRequestPeriod = "upcoming"|"past";

export interface BookingRequestService {
  key: string, 
  value: string
}

export interface BookingRequestOperator {
  email: string,
  name: string
}

export interface BookingRequestResponse {
  receivedOn?: Date,
  comment: string,
  status: BookingRequestStatus
}

export interface BookingRequestCollectiveInvoice {
  amount: number,
}

export class BookingRequest {
  id: string = "";
  createdOn: Date = new Date();
  services: BookingRequestService[] = [];
  operator: BookingRequestOperator = { email:"", name:"" };
  comment: string = "";
  response: BookingRequestResponse = { status:"open", comment: ""};
  providerId: string = "";
  providerName: string = "";
  activityDate: Date = new Date(0);
  adventureId: string = "";
  adventureIdShort: string = "";
  adventureDate: Date = new Date(0); 
  adventureStatus: number = 1;
  isEditable: boolean = false;
  isUpcoming: boolean = false;
  collectiveInvoice: BookingRequestCollectiveInvoice|null = null;
  
  getServiceValue(key: string): string {
    const service = this.services.find(s => s.key === key);
    if(service) {
      return service.value;
    }
    else {
      return "";
    }
  }

  getServices(options:{exclude: string[]}): BookingRequestService[] {
    const services = this.services.filter(s => {
      return options.exclude.includes(s.key) === false;
    })
    return services;
  }

  static async setResponse(bookingRequestId:string, accepted:boolean, comment:string, contact:string) : Promise<{status:"OK"|"ERROR"|"UNAUTHORIZED"|"INVALID_REQUEST", message:string}> {
    // assemble payload
    const payload = {
      id: bookingRequestId,
      accepted, contact, comment
    };

    // call api - Note: user is identified via the x-access-token in the header sent by the api
    const result = await callApi("setResponse", payload);

    // done
    return result;
  }

  static fromDb(br:any): BookingRequest {
    const bookingRequest = new BookingRequest();
    bookingRequest.id = br._id;
    bookingRequest.createdOn = new Date(br.request.createdOn);
    bookingRequest.adventureId = br.adventureId || "";
    bookingRequest.adventureIdShort = bookingRequest.adventureId.substr(bookingRequest.adventureId.length - 4);
    bookingRequest.adventureDate = br.adventureDate ? new Date(br.adventureDate) : new Date(0);
    bookingRequest.adventureStatus = br.adventureStatus || 0;
    bookingRequest.providerId = br.providerId;
    bookingRequest.providerName = br.providerName;

    // the activity's date
    if(_.get(br, "activity.date")) {
      bookingRequest.activityDate = new Date(br.activity.date);
    }
    else {
      // older booking requests do not have the activity's date ... we need to get it from the services
      const date = BookingRequest._getDateFromServices(br.services || []);
      if(date) {
        bookingRequest.activityDate = date;
      }
      else {
        bookingRequest.activityDate = bookingRequest.adventureDate;
      }
    }

    // requested services
    bookingRequest.services = (br.services || []).map((s:any) => {
      return {
        key: s.key,
        value: s.value
      }
    });
    // the operator
    bookingRequest.operator = {
      email: br.request.createdBy,
      name: br.request.createdBy // TODO extract name
    }; 
    // comment by operator
    bookingRequest.comment = br.request.comment;
    // the response & status
    bookingRequest.response = {
      status: "open", comment: ""
    };
    if(br.response) {
      bookingRequest.response.receivedOn = new Date(_.get(br, "response.receivedOn"));
      bookingRequest.response.comment = _.get(br, "response.comment") || "";
      bookingRequest.response.status = _.get(br, "response.accepted") || false ? "accepted" : "rejected";
    }
    // still editable?
    /*
    const daysBefore = moment(bookingRequest.adventureDate).add(-Config.bookingRequestThreshold, 'days').toDate();
    const today = new Date();
    bookingRequest.isEditable = daysBefore > today;
    */
    bookingRequest.isEditable = bookingRequest.response.status === "open";

    // is upcoming?
    bookingRequest.isUpcoming = bookingRequest.adventureDate > new Date();

    // collective invoice?
    if(br.collectiveInvoice) {
      bookingRequest.collectiveInvoice = {
        amount: br.collectiveInvoice.amount || 0
      }
    }

    // done
    return bookingRequest;
  }

  static async load(status: BookingRequestStatus) : Promise<{status:"OK"|"UNAUTHORIZED"|"ERROR", error:Error|null, bookingRequests:BookingRequest[]}> {
    // call api - Note: user is identified via the x-access-token in the header sent by the api
    const result = await callApi("getBookingRequests", {status});

    // error?
    let error = null;
    if(result.status === "ERROR") {
      error = result.error;
      console.error(error);
    }

    // booking requests
    const unsortedBookingRequests: Array<BookingRequest> = [];
    if(result.status === "OK") {
      result.bookingRequests.forEach((br:any) => {
        unsortedBookingRequests.push(BookingRequest.fromDb(br));
      });
    }

    // sort them
    const sortedBookingRequests = unsortedBookingRequests.sort((a,b) => {
      return a.activityDate > b.activityDate ? 1 : -1; 
    })

    // done
    return {
      status: result.status,
      error,
      bookingRequests: sortedBookingRequests
    };
  }

  static _getDateFromServices(services:BookingRequestService[]) : Date|null {
    try {
      const service_datum = services.find(s => s.key === "Datum");
      const service_zeit = services.find(s => s.key === "Zeit");
      if(service_datum && service_zeit) {
        // parse date
        let year:number|null = null;
        let month:number|null = null;
        let day:number|null = null;
        let datum = service_datum.value.replace(/[^0-9.]+/g, ""); // values are all over the place "Freitag, 01.12.23", "Freitag 01.12.23" ... reduce it to numbers and . 
        const datum_parts = datum.split(".");
        if(datum_parts.length === 3) {
          year = Number(datum_parts[2]);
          month = Number(datum_parts[1]) - 1;
          day = Number(datum_parts[0]);
        }
        
        // parse time
        let hour:number|null = null;
        let minute:number|null = null;
        const zeit = service_zeit.value.replace(".", ":").replace(/[^0-9]+/g, ""); // values are all over the place "11:00", "11.00", "11:00h", "11:00 Uhr", "11:00 Ur"
        const zeit_parts = zeit.split(":");
        if(zeit_parts.length === 2) {
          hour = Number(zeit_parts[0]);
          minute = Number(zeit_parts[1]);
        }
  
        // create date object
        // NOTE: this will not work properly if the user of the site is no using CET
        if(year !== null && month !== null && day !== null && hour !== null && minute !== null) {
          const date = moment(new Date(year, month, day, hour, minute, 0, 0));
          if(date.isValid()) {
            return date.toDate();
          }
        }
      }
      return null;
    }
    catch(err) {
      console.error(err);
      return null;
    }
    
    
  }
}


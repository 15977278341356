// components
import { Page, PageBlock } from "../layout/";
import { BookingRequests, CollectiveInvoices, Contact, Profile } from "../views";
// styling
import CSS from "./Dashboard.module.scss";

// the component
export default function Dashboard() {
  return (
    <Page>
      <PageBlock>
        <div className={CSS.container}>
          <div className={CSS.inner}>
            <div className={CSS.row}>
              <BookingRequests display="dashboard" />
              <Profile display="dashboard" />
            </div>
            <div className={CSS.row}>
              <Contact display="dashboard" />
              <CollectiveInvoices display="dashboard" />
            </div>
          </div>
        </div>
      </PageBlock>
    </Page>
  )
}
